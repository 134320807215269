@import url('https://fonts.googleapis.com/css2?family=Baloo+Paaji+2&display=swap');
@import 'bootstrap/dist/css/bootstrap.css';

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: 'Baloo Paaji 2', cursive;
}

.LinkTree {
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  margin-left: 20px;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
  margin-bottom: 5px;
}

.container-login {
  background-image: url('Imagenes/fondo1.jpg');
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 0px;
  width: 80%;
}

.contenedor-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 20px;
}

.logo1 {
  width: 70%;
  margin: auto;
  display: block;
}

.lado2 form input {
  margin-bottom: 20px;
}

.titulo1 {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 50px;
  text-align: center;
}

.titulo2 {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  margin-bottom: 15px;
}

.btn-iniciar-sesion {
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin: auto;
  display: block;
  background-color: #7f47dd;
  color: #FFFFFF;
}

.mensaje-error {
  padding: 5px;
  border-radius: 5px;
  color: red;
  font-weight: bold;
}

.container-uno {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  gap: 0px 0px;
  background-image: url('Imagenes/fondo1.jpg');
  background-size: cover;
}

.container-uno .bloque-1 {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #FFFFFF;
  min-height: 100vh;
}

.container-uno .bloque-2 {
  color: #FFFFFF;
  min-height: 100vh;
  overflow: hidden;
}

.container-uno .bloque-2 .header {
  padding: 20px;
  color: #FFFFFF;
  /* From https://css.glass */
  background: rgba(198, 198, 198, 0.63);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.2px);
  -webkit-backdrop-filter: blur(4.2px);
  border: 1px solid rgba(198, 198, 198, 0.23);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Container-nuevo-proyecto {
  border-radius: 5px;
  padding: 20px;
  color: #80727b;
  font-weight: bold;
  /* From https://css.glass */
  background: rgba(198, 198, 198, 0.63);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.2px);
  -webkit-backdrop-filter: blur(4.2px);
  border: 1px solid rgba(198, 198, 198, 0.23);
}

.Container-nuevo-proyecto1 {
  border-radius: 5px;
  padding: 20px;
  color: #FFFFFF;
  /* From https://css.glass */
  background: rgba(198, 198, 198, 0.63);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.2px);
  -webkit-backdrop-filter: blur(4.2px);
  border: 1px solid rgba(198, 198, 198, 0.23);
  width: 95%;
  margin: auto;
}

.Container-nuevo-proyecto1 .form-tareas {
  display: flex;
  align-items: center;
}

.Container-nuevo-proyecto1 .form-tareas input {
  margin-right: 20px;
}


.container-uno .bloque-3 {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #FFFFFF;
  min-height: 100vh;
}

.Logo2 {
  width: 80%;
  padding: 30px 0px;
  margin: auto;
  display: block;
}

.contenedor-reloj {
  display: flex;
  align-items: center;
  color: #00FFFF;
}

.contenedor-reloj p {
  margin-bottom: 0px;
}

.contenedor-reloj svg {
  font-size: 35px;
  margin-right: 10px
}

.btn-cerrar-sesion {
  background-color: transparent;
  border: solid 2px #FFFFFF;
  padding: 10px;
  color: #FFFFFF;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}

.contenedor-navegacion nav ul {
  list-style: none;
}

.contenedor-navegacion nav ul li {
  padding: 10px;
}

.contenedor-navegacion nav ul li a {
  text-decoration: none;
  color: inherit;
}

.contenedor-navegacion nav ul li a svg {
  color: #FFFFFF;
}

.container-uno .bloque-3 nav ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.container-uno .bloque-3 nav ul li {
  padding: 10px;
}

.container-uno .bloque-3 nav ul li a {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 20px;
  line-height: 18px;
  padding: 20px 0px;
}

.container-uno .bloque-3 nav ul li a svg {
  font-size: 30px;
  margin-bottom: 5px;
}

.contenedor-facturacion {
  padding: 20px;
  width: 100%;
}

.titulo3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 25px;
  font-weight: bold;
  color: #FFFFFF;
}

.contenedor-facturacion1 .titulo3 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 25px;
  font-weight: bold;
  color: #80727b;
}

.btn-pago {
  background: #00FFFF;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #8047DD, #00FFFF);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #8047DD, #00FFFF);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 5px;
  border-radius: 5px;
  border: none;
  border-radius: 10px;
  color: #FFFFFF;
  width: 150px;
}

.show {
  display: block !important;
  -webkit-animation: slide-in-left 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-left 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-8-4 11:53:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}


.contenedor-nuevo-pago {
  display: none;
  padding: 20px;
  border-radius: 10px;
  width: 800px;
  height: 350px;
  background-color: #292e5a;
  position: absolute;
  top: 158px;
  margin-left: -78px;
  overflow-y: scroll;
}

.btn-pago1 {
  border: solid 1px #FFFFFF;
  padding: 2px;
  font-size: 20px;
  border-radius: 5px;
  background-color: transparent;
  color: #FFFFFF;
  font-weight: bold;
}

.btn-pago1-1 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.table-info-pagos {
  padding: 20px;
}

.btn-factura {
  border: none;
  border-radius: 5px;
  padding: 5px;
  background-color: #7f47dd;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 15px;
}

.contenedor-factura {
  display: none;
  padding: 20px;
  border-radius: 10px;
  width: 800px;
  height: 85vh;
  background-color: #292e5a;
  position: absolute;
  top: 37px;
  margin-left: -78px;
  overflow-y: scroll;
}

.logoRecibo {
  width: 150px;
  height: auto;
}

.EstiloRecbo {
  margin-top: 20px;
}

.EstiloRecbo .HeaderRecibo {
  padding: 20px;
  background-color: #B5B5B5;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.BodyRecibo {
  background-color: #FFFFFF;
  padding: 30PX;
}

.fechaRecibo {
  color: #FFFFFF;
  margin-bottom: 0px;
}

.fechaRecibo span {
  font-size: 20px;
  font-weight: bold;
}

.tablaRecibo {
  font-size: 14px;
  color: #7f8387;
  width: 100%;
}

.tablaRecibo tbody tr {
  height: 40px;
  border-bottom: solid 1px #dee2e6;
}

.title-nuevo-proyecto {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #80727b
}

.Container-nuevo-proyecto form input {
  margin-bottom: 15px;
}

.btn-cerrar-sesion1 {
  color: #80727b;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px;
  width: 156px;
  margin: auto;
  display: block;
}

.grid-colores {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  margin-bottom: 15px;
}

.item-color .form-check {
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FFFFFF;
}

.item-color .form-check .form-check-input {
  margin-left: 0px;
}

.container-proyectos {
  margin: 30px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.container-proyectos a {
  text-decoration: none;
  color: inherit;

}

.container-proyectos a .cont-proyecto {
  height: 110px;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  color: #FFFFFF;
}

.Calendario {
  width: 90%;
  margin: auto;
}

.Table-calendario {
  width: 100%;
}

.current-day {
  background-color: #bbacc1;
}

.Table-calendario thead tr th {
  font-size: 12px;
  border: 1px solid #b5b5b5 !important;
}

.Table-calendario tbody tr td {
  border: 1px solid #b5b5b5 !important;
}

.Table-calendario tbody tr td p {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.titulo02 {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;
}

.btn-next-prev {
  background-color: #bbacc1;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  font-weight: 700;
  padding: 6px;
}

.bloque1 {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 30px 0px;
  width: 100%;
}

.titulo-proyecto {
  text-align: center;
  padding: 20px 0px;
  font-size: 25px;
  font-weight: bold;
}

.titulo-proyecto span {
  color: #00FFFF;
}

.container-lista-tareas-proyecto {
  padding: 25px;
}

.title-tarea {
  text-align: center;
  font-size: 23px;
  margin-bottom: 20px;
}

.cont-tarea1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.contenedor-facturacion1 {
  padding: 20px;
  width: 100%;
  background-color: #FFFFFF;
  color: #000000;
  min-height: 100vh;
}

.fecha_tarea {
  color: #c6c6c6;
  font-size: 15px;
  margin-bottom: 4px;
}

.btn1 {
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 5px;
  background-color: #7f47dd;
  font-size: 14px;
  margin-right: 10px;
}

.btn2 {
  border: none;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
  padding: 5px;
  background-color: #bf4342;
}

.subrayado {
  text-decoration: line-through;
  color: #63A375;
}

.container-nuevo-fecha {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr;
  gap: 10px;
  width: 90%;
  margin: auto;
  padding-bottom: 30px;
}

.form-evento input {
  margin-bottom: 10px;
}

.Contenedor-lista-eventos {
  padding: 30px 0px;
}

.card-eventos {
  /* From https://css.glass */
  background: rgba(127, 71, 221, 0.31);
  border-radius: 5px;
  color: #80727b;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border: 1px solid rgba(127, 71, 221, 0.3);
  padding: 20px;
}

.grid-eventos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.card-eventos p {
  margin-bottom: 2px;
}

.card-eventos p span {
  font-weight: bold;
}

.acciones-evento {
  border-top: 1px solid rgba(127, 71, 221, 0.3);
  margin-top: 20px;
  padding: 10px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.contenedor-editar-evento {
  padding: 20px;
  color: #FFFFFF;
  /* From https://css.glass */
  background: rgba(198, 198, 198, 0.63);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.2px);
  -webkit-backdrop-filter: blur(4.2px);
  border: 1px solid rgba(198, 198, 198, 0.23);
  padding: 20px;
  margin: 20px;
}

.btn3 {
  padding: 5px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  background-color: #7f47dd;
  color: #FFFFFF;
}

.btn3 a {
  text-decoration: none;
  color: inherit;
}

.btn4 {
  background-color: #bf4342;
  color: #FFFFFF;
  padding: 5px;
  border: none;
  border-radius: 5px;
}

.grid-container-contactenos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.mapa {
  width: 100%;
  height: 100%;
}

.img-contacto {
  width: 80%;
  margin: auto;
  display: block;
  padding-bottom: 30px;
}

.grid-tech-item1 .btn-pago {
  text-decoration: none;
  margin: auto;
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.img-grupo {
  width: 60%;
  padding: 30px;
  margin: auto;
  display: block;
  border-radius: 10px;
}

.btn-cerrar-sesion2 {
  background-color: transparent;
  border: solid 2px #FFFFFF;
  padding: 10px;
  color: #FFFFFF;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  display: none;
  font-size: 20px;
}

.container-check {
  display: flex;
  margin-bottom: 10px;
}

.container-check p {
  padding-right: 15px;
  margin-bottom: 0px;
}

.container-check .form-check{
  margin-right: 15px;
}

.form-evento .form-select {
  margin-bottom: 10px !important;
}

.form-evento .form-floating{
  margin-bottom: 10px !important;
}

.texto-form{
  margin-bottom: 0px;
}

.form-check-input{
  margin-right: 5px;
}

.label-check{
  margin-right: 15px;
}
@media only screen and (max-width:600px) {
  .img-grupo {
    width: 100%;
    padding: 30px;
    margin: auto;
    display: block;
    border-radius: 10px;
  }

  .posicion {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .contenedor-navegacion nav ul li a {
    text-decoration: none;
    color: inherit;
    text-shadow: 4px 2px 5px #FFFFFF;
  }

  .contenedor-navegacion nav ul li {
    color: #8047DD;
    font-size: 18px;
  }

  .contenedor-navegacion nav ul li a svg {
    margin-right: 10px;
    color: #8047DD;
  }

  .cont-tarea1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .container-proyectos {
    margin: 30px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 100%;
  }

  .grid-colores {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-bottom: 15px;
    width: 100%;
  }

  .contenedor-factura {
    display: none;
    padding: 20px;
    border-radius: 10px;
    width: 95%;
    height: 85vh;
    background-color: #292e5a;
    position: absolute;
    top: 37px;
    margin-left: 0;
    overflow-y: scroll;
  }

  .contenedor-nuevo-pago {
    display: none;
    padding: 20px;
    border-radius: 10px;
    width: 95%;
    height: 350px;
    background-color: #292e5a;
    position: absolute;
    top: 158px;
    margin-left: 0;
    overflow-y: scroll;
  }

  .expanded {
    display: block !important;
  }

  .btn-cerrar-sesion2 {
    display: block;
  }

  .container-uno .bloque-1 {
    background: #B5B5B5;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #FFFFFF;
    min-height: 100vh;
    position: absolute;
    z-index: 99;
    display: none;
    width: 100vw;
  }

  .container-uno .bloque-2 {
    color: #FFFFFF;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    width: 100vw;
  }
}

@media only screen and (max-width:768px) {
  .img-grupo {
    width: 100%;
    padding: 30px;
    margin: auto;
    display: block;
    border-radius: 10px;
  }

  .posicion {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .contenedor-navegacion nav ul li a {
    text-decoration: none;
    color: inherit;
    text-shadow: 4px 2px 5px #FFFFFF;
  }

  .contenedor-navegacion nav ul li {
    color: #8047DD;
    font-size: 18px;
  }

  .contenedor-navegacion nav ul li a svg {
    margin-right: 10px;
    color: #8047DD;
  }

  .cont-tarea1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .container-proyectos {
    margin: 30px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 100%;
  }

  .grid-colores {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-bottom: 15px;
    width: 100%;
  }

  .contenedor-factura {
    display: none;
    padding: 20px;
    border-radius: 10px;
    width: 95%;
    height: 85vh;
    background-color: #292e5a;
    position: absolute;
    top: 37px;
    margin-left: 0;
    overflow-y: scroll;
  }

  .contenedor-nuevo-pago {
    display: none;
    padding: 20px;
    border-radius: 10px;
    width: 95%;
    height: 350px;
    background-color: #292e5a;
    position: absolute;
    top: 158px;
    margin-left: 0;
    overflow-y: scroll;
  }

  .expanded {
    display: block !important;
  }

  .btn-cerrar-sesion2 {
    display: block;
  }

  .container-uno .bloque-1 {
    background: #B5B5B5;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #FFFFFF;
    min-height: 100vh;
    position: absolute;
    z-index: 99;
    display: none;
    width: 100vw;
  }

  .container-uno .bloque-2 {
    color: #FFFFFF;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    width: 100vw;
  }
}